import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages";
import Funds from "./pages/funds"
import FundsDetail from "./pages/fundsDetail"
import Legal from "./pages/legal";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/funds" component={Funds} exact />
        <Route path="/funds/:id" component={FundsDetail} exact />
        <Route path="/legal" component={Legal} exact />
      </Switch>
    </Router>
  );
}

export default App;
