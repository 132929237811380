import React, { useState, useEffect } from "react";

import Vector from '../images/Vector-3.svg'
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import SignIn from "../components/SignIn";
import LegalPageContent from "../components/CompanyLegal";
import Footer from "../components/FooterSection";

import { DetailPropsProvider } from "../detailProvider"

import axios from "axios";
import qs from "qs";

const serverUrl = process.env.REACT_APP_STRAPI_SERVER;
const token = process.env.REACT_APP_STRAPI_TOKEN;
function Legal() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const [config, setConfig] = useState([]);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  const toggleSignIn = () => {
    setIsSignInOpen((prev) => !prev);
  };

  const companies = [
    {
      name: 'Ocean Finance',
      folder: 'ocean_finance', 
    },
    {
      name: 'GRIFFON MONEY INC',
      folder: 'griffon', 
    },
    {
      name: 'EL (AUS) PTY LTD',
      folder: 'el', 
    }
    // {
    //   name: 'Nanos Exchange Digital Assets',
    //   folder: 'nanos', 
    // },
  ];

  const query = qs.stringify({ 
    populate: '*',
    fields: '*',
    publicationState: 'live',
    locale: ['en'],
  }, {
    encodeValuesOnly: true, // prettify url
  });

  const axiosConfig = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const getAllConfig = async () => {
    try {
      // fetch config from strapi
      const res = await axios.get(`${serverUrl}/api/configs?${query}`,axiosConfig);
      // get result from nested object destructuring
      const {
        data: { data },
      } = res;
      setConfig(data);
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    getAllConfig();
  }, []);

  return (
    <DetailPropsProvider props={config}>
      <div className="flex justify-center bg-primary">
        <div className="w-full items-center">
          <Sidebar isOpen={isOpen} toggle={toggle} />
          <SignIn isOpen={isSignInOpen} toggle={toggleSignIn} />
          <Navbar toggle={toggle} toggleSignIn={toggleSignIn} page="legal"/>
          <div id="legal" className="m-5 py-36">
            <div className="leading-120 uppercase text-41 w-3/5 font-semibold sm:w-full sm:text-xl pb-20">
                LEGAL PAGES
            </div>
            <div className="flex flex-wrap gap-16 justify-between">
                {companies.map((company, index) => (
                    <LegalPageContent
                    key={index}
                    companyName={company.name}
                    folderName={company.folder}
                    />
                ))}
            </div>
          </div>
          <Footer/>
        </div>
      </div>
      </DetailPropsProvider>
  );
}

export default Legal;
