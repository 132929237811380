import React, { useState } from "react";
import vector from '../../images/Vector-2.svg';
import vectorDown from '../../images/light-vector.svg'
import { useDetailProps } from '../../detailProvider';

import { getValue } from '../../utils';
function HeroSection() {
  const pdfFiles = [
    {company: 'ocean_finance', fileUrl: require(`../../pdf/ocean_finance/corporate_information.pdf`).default },
    {company: 'griffon', fileUrl: require(`../../pdf/griffon/corporate_information.pdf`).default },
    {company: 'el', fileUrl: require(`../../pdf/el/corporate_information.pdf`).default }
  ];

  const detailProps = useDetailProps();

  return (
    <div className="flex flex-col items-end">
      <div className="flex justify-center uppercase bg-primary pb-4 pl-4 xl:pl-v1.5 xxl:pl-v1.5 pr-v7 items-center text-100 leading-120 sm:text-4xl font-semibold" style={{marginTop:'80px',paddingTop:'20px'}}>
        { getValue(detailProps, 'title') }
      </div>
      <div class="text-sm w-full text-left pl-4 xl:pl-v1.5 xxl:pl-v1.5 pr-v7">Licensed by</div>
      <div className="w-full mb-14 flex pb-4 pl-4 xl:pl-v1.5 xxl:pl-v1.5 pr-4 justify-between items-center">
        <div class="flex flex-wrap flex-row-reverse justify-end text-12 xl:text-12 xxl:text-12 font-semibold gap-3">
          <a onClick={() => window.open(pdfFiles[2].fileUrl)} class="text-sm cursor-pointer text-wrap gap-2 h-auto w-full text-yellow-1 bg-black border-2 border-black rounded-3xl px-2 max-w-max flex"><img src={vectorDown} class="h-3.5 my-auto"/>Australian Securities & Investments Commission (ASIC)</a>
          <a onClick={() => window.open(pdfFiles[1].fileUrl)} class="text-sm cursor-pointer text-wrap gap-2 h-auto w-full text-yellow-1 bg-black border-2 border-black rounded-3xl px-2 max-w-max flex"><img src={vectorDown} class="h-3.5 my-auto"/>
            <p class="text-wrap">
              The Financial Transactions and Reports Analysis Centre of Canada (FINTRAC)
            </p>
          </a>
          <a onClick={() => window.open(pdfFiles[0].fileUrl)} class="text-sm cursor-pointer text-wrap gap-2 h-auto w-full text-yellow-1 bg-black border-2 border-black rounded-3xl px-2 max-w-max flex"><img src={vectorDown} class="h-3.5 my-auto"/>Cayman Islands Monetary Authority (CIMA)</a>
        </div>
        <img src={vector} className="relative right-0 w-24xl h-10 top-0 sm:hidden md:hidden lg:hidden" style={{minWidth:'24px', float: 'right'}}/>
      </div>
    </div>
  );
}

export default HeroSection;
