import React from "react";
import {
  ContactContainer,
  SidebarLinkWrap,
  NavLogo,
  NavBtn,
  NavBtnLink,
  SidebarWrapper,
  FormContainer,
  SideBtnWrap,
  SidebarRoute,
  SidebarDescription
} from "./SignInElements";

import logo from '../../images/Logo(black-small).svg';

function SignIn({ isOpen, toggle }) {
  return (
    <ContactContainer isOpen={isOpen}>
      <SidebarLinkWrap>
          <NavLogo to="/" onClick={toggle}>
            <img src={logo}/>
          </NavLogo>
          <NavBtn>
            <NavBtnLink onClick={toggle}>Close</NavBtnLink>
          </NavBtn>
      </SidebarLinkWrap>
      <SidebarWrapper>
        <div className="uppercase text-black leading-120 text-8xl lg:text-7xl md:text-5xl sm:text-3xl mt-40 font-semibold">
          the user area
        </div>
        <div className="flex">
          <div className="uppercase text-black leading-120 text-8xl lg:text-7xl md:text-5xl sm:text-3xl mb-5 font-semibold">
            Coming soon
          </div>
          <div className="text-5xl lg:text-3xl md:text-2xl sm:text-xl ml-5 md:ml-4 sm:ml-2 mt-4 md:mt-3 sm:mt-0.5">Stay tuned</div>
        </div>
        <div className="text-sm text-black mb-72">
          welcome@nanospay.ai
        </div>
      </SidebarWrapper>
    </ContactContainer>
  );
}

export default SignIn;
