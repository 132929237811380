import React, { useState, useEffect } from "react";
import vector from '../../images/Vector-2.svg';
import { animateScroll as scroll } from "react-scroll";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";
import logo from '../../images/Logo(black-small).svg';

function Navbar({ toggle, toggleSignIn, page }) {
  const pdf = require(`../../pdf/nanos_whitepaper.pdf`).default

  const [scrollNav, setScrollNav] = useState(false);
  const location = useLocation();

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  const showLinks = (page === 'funds' || page === 'legal') ? false : true;

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const scrollProps = {
    activeClass: "border-solid border-b-4 border-green-500",
    smooth: true,
    duration: 500,
    spy: true,
    exact: "true",
    offset: -80,
  };

  const history = useHistory();

  const scrollToSection = (sectionId) => {
    // If on the funds page (main or detail), go back to the home page and scroll to the section
    if (location.pathname.startsWith("/funds") || location.pathname.startsWith("/legal")) {
      history.push("/");
      scroll.scrollTo(sectionId, scrollProps);
    } else {
      // If on any other page, scroll to the section
      scroll.scrollTo(sectionId, scrollProps);
    }
  };

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            <img className="object-cover w-full" src={logo} style={{height:'auto', objectFit:'contain'}}/>
          </NavLogo>
          <MobileIcon>
            <NavBtnLink onClick={() => window.location.href = "https://app.alana.finance/"}>Login</NavBtnLink>
            {showLinks ? (
              <img onClick={toggle} className="ml-8" src={vector} />
            ) : (
              <img onClick={() => scrollToSection("about")} className="ml-8" style={{ transform: 'rotate(90deg)' }} src={vector} />
            )}
          </MobileIcon>
            <NavMenu>
            {showLinks && (
              <>
              <NavItem>
                <NavLinks to="about" {...scrollProps} onClick={() => scrollToSection("about")}>
                  ABOUT
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="service" {...scrollProps} onClick={() => scrollToSection("service")}>
                  SERVICES
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="platform" {...scrollProps} onClick={() => scrollToSection("platform")}>
                  PLATFORM
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="funds" {...scrollProps} onClick={() => scrollToSection("funds")} >
                  FUNDS
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="innovation" {...scrollProps} onClick={() => scrollToSection("innovation")}>
                  INNOVATION
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="token" {...scrollProps} onClick={() => scrollToSection("token")}>
                  TOKEN
                </NavLinks>
              </NavItem>
              </>
              )}
              <NavItem>
                <NavLinks to="contact" {...scrollProps}>
                  CONTACTS
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks {...scrollProps}>
                <a onClick={() => window.open(pdf)} target="_blank" rel="noopener noreferrer">
                        WHITEPAPER
                    </a>
                </NavLinks>
              </NavItem>
              <NavBtn>
                <NavBtnLink onClick={() => window.location.href = "https://app.alana.finance/"}>Login</NavBtnLink>
              </NavBtn>
            </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
}

export default Navbar;
