import React,{ useState } from 'react';
import arrow from '../../images/arrow_down.svg';
import { useHistory } from 'react-router-dom';
import vector from '../../images/Vector-2.svg';
import { useDetailProps } from '../../detailProvider';
import { useParams } from 'react-router-dom';
import folder from '../../images/folder.svg';
import btc from '../../images/BTC.svg';
import eth from '../../images/ETH.svg';
import usdt from '../../images/USDT.svg';
import Carousel from 'react-multi-carousel';

//This should have it's own object in strapi with the following entries for currencies and yearly returns
const Funds = ({funds, statistics}) => {
    const pdfPresentation = require(`../../pdf/nanospay_presentation.pdf`).default
    const pdfReport = require(`../../pdf/nanos_report.pdf`).default
    const { id } = useParams();
    const index = parseInt(id);
    const history = useHistory();

    const responsive = {
      superLargeDesktop4: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3871 },
        items: 13.5
      },
      superLargeDesktop3: {
        breakpoint: { max: 3870, min: 3634 },
        items: 12.5
      },
      superLargeDesktop2: {
        breakpoint: { max: 3633, min: 3331 },
        items: 11.5
      },
      superLargeDesktop1: {
        breakpoint: { max: 3330, min: 3031 },
        items: 10.5
      },
      superLargeDesktop0: {
        breakpoint: { max: 3030, min: 2731 },
        items: 9.5
      },
      LargeDesktop4: {
        breakpoint: { max: 2730, min: 2431 },
        items: 8.5
      },
      LargeDesktop3: {
        breakpoint: { max: 2430, min: 2131 },
        items: 7.5
      },
      LargeDesktop2: {
        breakpoint: { max: 2130, min: 1831 },
        items: 6
      },
      LargeDesktop1: {
        breakpoint: { max: 1830, min: 1661 },
        items: 5.5
      },
      desktop6: {
        breakpoint: { max: 1660, min: 1581 },
        items: 5.25
      },
      desktop5: {
        breakpoint: { max: 1580, min: 1501 },
        items: 5
      },
      desktop4: {
        breakpoint: { max: 1500, min: 1421 },
        items: 4.75
      },
      desktop3: {
        breakpoint: { max: 1420, min: 1331 },
        items: 4.5
      },
      desktop2: {
        breakpoint: { max: 1330, min: 1231 },
        items: 4
      },
      desktop1: {
        breakpoint: { max: 1230, min: 1141 },
        items: 3.75
      },
      smallDesktop: {
        breakpoint: { max: 1140, min: 1041 },
        items: 3.5
      },
      tablet3: {
        breakpoint: { max: 1040, min: 971 },
        items: 3.25
      },
      tablet2: {
        breakpoint: { max: 970, min: 901 },
        items: 3
      },
      tablet1: {
        breakpoint: { max: 900, min: 871 },
        items: 2.75
      },
      largeMobile: {
        breakpoint: { max: 870, min: 771 },
        items: 2.5
      },
      middleMobile: {
        breakpoint: { max: 770, min: 691 },
        items: 2.25
      },
      mobile2: {
        breakpoint: { max: 690, min: 616 },
        items: 2
      },
      mobile1: {
        breakpoint: { max: 615, min: 546 },
        items: 1.75
      },
      smallMobile3: {
        breakpoint: { max: 545, min: 471 },
        items: 1.5
      },
      smallMobile2: {
        breakpoint: { max: 470, min: 401 },
        items: 1.25
      },
      smallMobile1: {
        breakpoint: { max: 400, min: 0 },
        items: 1.05
      }
    };
    const detailProps = useDetailProps();
    //This should be redone in strapi as an object to have year inputs or something similar.
    const currenciesArray = [
      ['2024','2023','2022'],
      ['2024','2023'],
      ['BTC', 'ETH', 'XRP', 'ETH/BTC']
    ]
    
    const currencyIcons = {
      'BTC': btc,
      'ETH': eth,
      'USDT': usdt
    };
    //This should be transferred to strapi as an object.
    const fundsList = [
      {
        closed: false,
        name: "Liquidity Delta Strategy",
        description: "A Liquidity Delta Strategy Crypto Fund utilizes artificial intelligence and machine learning algorithms to make informed investment decisions in the cryptocurrency market. The fund’s strategy involves leveraging advanced data analytics and computational models to analyze market trends, identify trading opportunities, and execute trades automatically.",
        liquidity: "1INCH, ACE, ACH, ADA, AEVO, AGI, ALT, APE, APEX, API3, APT, AR, ARB, ARK, ARKM, ASTR, ATOM, AVAX, AXS, BAL, BCH, BEAM, BLUR, BNB, BTC, C98, CELO, CFX, CHZ, COMP, COTI, CRV, DOGE, DOT, DUSK, DYM, ENA, ENS, EOS, ETC, ETH, ETHW, FET, FIDA, FIL, FLOKI, FTM, GALA, GAS, GLM, GMX, GRT, HBAR, HOOK, ICP, ID, IMX, INJ, IO, IOTX, JTO, JUP, KNC, LDO, LINK, LTC, MAGIC, MASK, MINA, MKR, MNT, NEAR, NEO, ONDO, OP, ORDI, PENDLE, PYTH, RENDER, RUNE, SEI, SHIB, SNX, SOL, SSV, STRK, STX, SUI, SUSHI, TIA, TON, WIF, WLD, XRP, XTZ, ZETA, ZK",
        launchDate: "January 2024",
        minimumInvestment: "$100,000",
        investorType: "Accredited & Non Accredited",
        subscriptions: "Daily, Upon One Day’s Notice",
        redemptions: "Daily, Upon One Day’s Notice",
        statistics: ["1INCH","ACE","ACH","ADA","AEVO","AGI","ALT","APE","APEX","API3","APT","AR","ARB","ARK","ARKM","ASTR","ATOM","AVAX","AXS","BAL","BCH","BEAM","BLUR","BNB","BTC","C98","CELO","CFX","CHZ","COMP","COTI","CRV","DOGE","DOT","DUSK","DYM","ENA","ENS","EOS","ETC","ETH","ETHW","FET","FIDA","FIL","FLOKI","FTM","GALA","GAS","GLM","GMX","GRT","HBAR","HOOK","ICP","ID","IMX","INJ","IO","IOTX","JTO","JUP","KNC","LDO","LINK","LTC","MAGIC","MASK","MINA","MKR","MNT","NEAR","NEO","ONDO","OP","ORDI","PENDLE","PYTH","RENDER","RUNE","SEI","SHIB","SNX","SOL","SSV","STRK","STX","SUI","SUSHI","TIA","TON","WIF","WLD","XRP","XTZ","ZETA","ZK"],
        defaultStatistic: "2022",
        type: "base"
      },
      {
        closed: false,
        name: "Liquidity Delta Strategy Stablecoin",
        description: "The fund’s strategy involves leveraging advanced data analytics and computational models to analyze market trends, identify trading opportunities, and execute trades automatically.\\n\\\\n\\ To enhance equity protection, the fund focuses on stable pairs with consistent price behavior. This approach may lead to slightly lower immediate returns but significantly reduces risk by minimizing exposure to extreme volatility, creating a more secure investment environment.\\n\\\\n\\ This strategy promotes steady profit growth while significantly reducing the potential for major losses, providing investors with a balanced risk-reward profile focused on stability. Additionally, the fund’s emphasis on maintaining liquidity enables quick market adjustments, ensuring efficient trade execution with minimal slippage.\\n\\\\n\\ Liquidity Delta Stable Strategy Crypto Fund combines cutting-edge technology with a focus on stability and risk management, positioning itself as a resilient investment vehicle capable of delivering good profits while protecting equity.",
        liquidity: "AAVE, ACE, APE, ASTR, API3, APT, AR, ARB, ASTR, ATOM, BABYDOGE, BCH, CKB, CHZ, COTI, DOT, DYDX, ENJ, ENS, FIL, FTM, GALA, GLM, GRT, FLOKI, IMX, INJ, LINK, LPT, MEME, MINA, MKR, ORDI, PHB, PYTH, RSR, RUNE, SNX, SSV, TIA, VANRY, WLD",
        launchDate: "January 2024",
        minimumInvestment: "$100,000",
        investorType: "Accredited & Non Accredited",
        subscriptions: "Daily, Upon One Day’s Notice",
        redemptions: "Monthly, Upon One Day’s Notice",
        statistics: ["AAVE","ACE","APE","ASTR","API3","APT","AR","ARB","ASTR","ATOM","BABYDOGE","BCH","CKB","CHZ","COTI","DOT","DYDX","ENJ","ENS","FIL","FTM","GALA","GLM","GRT","FLOKI","IMX","INJ","LINK","LPT","MEME","MINA","MKR","ORDI","PHB","PYTH","RSR","RUNE","SNX","SSV","TIA","VANRY","WLD"],
        defaultStatistic: "2023",
        type: "stable"
      },
      {
        closed: true,
        name: "Levels AI Algorithm Trading Crypto Fund",
        description: "A Levels AI Algorithm Trading Crypto Fund utilizes artificial intelligence and machine learning algorithms to make informed investment decisions in the cryptocurrency market. The fund's strategy involves leveraging advanced data analytics and computational models to analyze market trends, identify trading opportunities, and execute trades automatically.",
        liquidity: "BTC/USDT/ETH/XRP/USDC",
        launchDate: "January 2024",
        minimumInvestment: "$100,000",
        investorType: "Accredited & Non Accredited",
        subscriptions: "Daily, Upon One Day’s Notice",
        redemptions: "Monthly, Upon One Day’s Notice",
        statistics: ["BTC","ETH","XRP","ETH/BTC"],
        defaultStatistic: "BTC",
        type: "ai"
      }
    ]

    const fund = fundsList[index];
    const shownCurrencies = currenciesArray[index];

    const [selectedCurrency, setSelectedCurrency] = useState(fund.defaultStatistic);
    const filteredEntry = statistics.find((data) => data.attributes.currencies === selectedCurrency && 
    data.attributes.currencyType1 === fund.type);
    const statistic = filteredEntry ? filteredEntry.attributes : [];
    const entries = statistic.entries;
    return (
        <div id='fundsDetail'>
            <div className="holder pt-36">
            <div className="ml-5 flex items-center mb-6 cursor-pointer"  onClick={() => history.goBack()}>
              <img src={vector} alt="Back" style={{ transform: 'rotate(90deg)' }} className="mr-2" /> 
              <span className="text-lg font-bold">Back</span>
            </div>
                <div className='py-8 px-5'>
                {fund.closed ? (
                    <div className='text-sm text-yellow-1 bg-black border-2 border-black rounded-3xl px-2 w-max mb-4'>Funding Closed</div>
                ) : (
                    <div className='text-sm border-2 border-black rounded-3xl px-2 w-max mb-4'>Funding Open</div>
                )}
                    <div className='flex justify-between mb-6 lg:grid lg:grid-cols-1'>
                        <div className='max-w-2xl'>
                            <div className="text-normal text-5xl font-bold mb-6 uppercase">
                              {fund.name}
                            </div>
                            {fund.description.split("\\n\\").map((line, index) => (
                              <span key={index}>
                                {line}
                                <br />
                              </span>
                            ))}
                            {/* <div className="text-normal font-bold mt-4">
                            Modification in the Levels fund:
                            </div>
                            <div className="text-normal mt-2">
                            The blocking of funds is for 36 months and liquidity can be taken monthly (Profits) The annual return is between 37.11% to 61.29% annually paid in USDT. Of this 70% goes directly to the client, 10% to the referral system and 20% paid to the company as a fund management fee.
                            </div> */}
                            <div className='flex gap-3'>
                              <div onClick={() => window.open(pdfPresentation)} target="_blank" className='cursor-pointer text-sm font-bold border-2 py-2 px-3 border-black rounded-3xl px-2 w-max my-4'>Download Presentation</div>
                              <div onClick={() => window.open(pdfReport)} target="_blank" className='cursor-pointer text-sm font-bold border-2 py-2 px-3 border-black rounded-3xl px-2 w-max my-4'>Download test report</div>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 gap-y-14 md:mt-5'>
                            <div className='flex flex-col'>
                                <div className="text-normal" style={{display: "flex", alignItems: "stretch",flex: 1}}>Liquidity:</div>
                                <div className="text-normal">Launch:</div>
                                <div className="text-normal">Minimum Investment:</div>
                                <div className="text-normal">Investor Type:</div>
                                <div className="text-normal">Subscriptions:</div>
                                <div className="text-normal">Redemptions:</div>
                            </div>
                            <div className='flex flex-col'><div className="text-normal font-bold" style={{ maxWidth: "390px", display: "flex",alignItems: "stretch"}}>Daily and assets: {fund.liquidity}</div>
                                
                                <div className="text-normal font-bold">{fund.launchDate}</div>
                                <div className="text-normal font-bold">{fund.minimumInvestment}</div>
                                <div className="text-normal font-bold">{fund.investorType}</div>
                                <div className="text-normal font-bold">{fund.subscriptions}</div>
                                <div className="text-normal font-bold">{fund.redemptions}</div>
                            </div>
                            <div className='px-12 py-9 border-dashed border-black border-2 rounded-half-full max-h-72 max-h-full col-span-2'>
                                <div className='text-2xl uppercase font-bold'>Contact to Join the fund</div>

                                <div className='font-bold mt-14'>International investor (Non-US)</div>
                                <div className='underline'><a href="mailto:invest@nanospay.ai"></a>invest@nanospay.ai</div>

                                <div className='font-bold mt-7'>Local investor (UAE)</div>
                                <div className='underline'><a href="mailto:invest@nanospay.ai"></a>invest@nanospay.ai</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='text-5xl sm:text-lg uppercase mt-10 mb-12 font-bold leading-160 text-left ml-5'>
                  Numbers from backtesting
                </div>

                <div className='relative h-96 m-5'>
                    <div className='relative sm:pb-10'>
                    {shownCurrencies.map((currency) => (
                    // Check if the current currency matches the selected currency
                    <button
                        key={currency}
                        className={`border-2 cursor-pointer px-4xl-1 text-15 md:text-base font-bold py-2xl-1 uppercase border-gray-250 active:border-black active:bg-black hover:border-black hover:bg-black crypto-buttons text-black ml-1 rounded-xxl ${
                            selectedCurrency === currency ? 'active' : ''
                        }`}
                        onClick={() => setSelectedCurrency(currency)}
                    >
                        {currency}
                    </button>
                ))}
                    {statistic.averageReturn !== null ? (
                      <div className='flex flex-col border-2 bg-black average-return pt-3 pb-3 pr-4 pl-4 absolute top-0 right-0 sm:top-12' style={{borderRadius: '30px'}}>
                        <div className='text-yellow-1 text-10 uppercase'>Average return</div>
                        <div className='text-yellow-1 text-16 md:text-xl mt-2 font-bold'>
                          {statistic.averageReturn}%
                        </div>
                      </div>
                    ) : null}
                    </div>
                    <Carousel 
                    responsive={responsive}
                    swipeable={true}
                    draggable={true}
                    ssr={false}
                    arrows={false}
                    showDots={false}
                    autoPlay={false}
                    infinite={false}
                    keyBoardControl={true}
                    itemClass="card"
                    className='mt-20 folder'
                    >
                    {(statistics.length > 0) && (
                    entries.data
                    .sort((a, b) => {
                      if (a.attributes.year < b.attributes.year) return -1;
                      if (a.attributes.year > b.attributes.year) return 1;
                      return 0;
                    })
                    .map((stat) => (
                        <div key={stat.id} className="card" style={{ width: '280px', minHeight:'210px' }}>
                        <div className="text-bold text-lg folder-year pr-8 pt-3 font-bold text-right folder-header">{stat.attributes.year}</div>
                        <div className='text-bold font-bold folder-head pl-7'> {stat.attributes.return.toFixed(2)}%</div>
                        {/* <div className='flex gap-5 justify-center folder-foot'>
                            <div className='flex gap-1 text-bold font-bold'>
                            <img src={currencyIcons[statistic.currencyType1]} alt="" />
                            {stat.attributes.volume1}
                            </div>
                            <div className='flex gap-1 text-bold font-bold'>
                            <img src={currencyIcons[statistic.currencyType2]} alt="" />
                            {stat.attributes.volume2}
                            </div>
                        </div> */}
                        <img src={folder} style={{ position: 'absolute', zIndex: 0, top: 0}} draggable="false" alt="" />
                        </div>
                    )))}
                    </Carousel>
                </div>
            </div>
        </div>
    )
}
export default Funds