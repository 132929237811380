import React, { useState } from 'react';
import vector from '../../images/Vector-4.svg';
import svg from '../../images/Group-big.svg';
import tabBackground from '../../images/tab_bg.svg';
import Marquee from "react-fast-marquee";

import { useDetailProps } from '../../detailProvider';

import { getValue } from '../../utils';

const Innovation = ({innovation}) => {

  const detailProps = useDetailProps();
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "RANGE ORDERS",
      header: "FLEXIBILITY AND CONTROL",
      text: "Traditional exchanges offer limit orders, where you set a fixed buy or sell price. But what if you could set a price range, and your order would execute as the price moves within that range? This is the essence of Range Orders on NanosDEX.",
      content: (
        <>
          <div className="bg-yellow-1 flex-1 text-black p-5 rounded-3xl mb-4">
            <h3 className="uppercase text-2xl font-semibold">FLEXIBILITY</h3>
            <p>You can precisely specify the price range within which you want to buy or sell assets, adapting your strategy to current market conditions.</p>
          </div>
          <div className="bg-yellow-1 flex-1 text-black p-5 rounded-3xl mb-4">
            <h3 className="uppercase text-2xl font-semibold">CONTINUOUS EXECUTION</h3>
            <p>Your order executes gradually as the price moves, allowing you to respond more accurately to market fluctuations.</p>
          </div>
          <div className="bg-yellow-1 flex-1 text-black p-5 rounded-3xl">
            <h3 className="uppercase text-2xl font-semibold">RISK REDUCTION</h3>
            <p>Range Orders help avoid sudden price jumps and minimize risks associated with market volatility.</p>
          </div>
        </>
      )
    },
    {
      title: "DUAL LIQUIDITY POSITION",
      header: "Optimization and Fairness",
      text: "NanosDEX also offers a unique model of a dual liquidity position. Unlike separate liquidity pools, a single common position is managed collectively.",
      content: (
        <>
          <div className="bg-yellow-1 flex-1 text-black p-5 rounded-3xl mb-4">
            <h3 className="uppercase text-2xl font-semibold">Fees</h3>
            <p>You can precisely specify the price range within which you want to buy or sell assets, adapting your strategy to current market conditions.</p>
          </div>
          <div className="bg-yellow-1 flex-1 text-black p-5 rounded-3xl mb-4">
            <h3 className="uppercase text-2xl font-semibold">Revenue distribution</h3>
            <p>Position management is proportional to contributions, ensuring fair revenue distribution among participants.</p>
          </div>
          <div className="bg-yellow-1 flex-1 text-black p-5 rounded-3xl">
            <h3 className="uppercase text-2xl font-semibold">Adaptability</h3>
            <p>Position boundaries are determined based on statistical data (mean values and dispersions), allowing adaptation to changing market conditions.</p>
          </div>
        </>
      )
    },
    {
      title: "INNOVATIVE LIQUIDITY MODEL",
      header: "Simplicity and Efficiency",
      text: "The liquidity model in NanosDEX is based on a trapezoidal profile. This means that liquidity changes linearly as the price moves within the range. For more complex shapes, several trapezoids can be combined, creating flexible and adaptive liquidity profiles.",
      content: (
        <>
          <div className="bg-yellow-1 flex-1 lex flex-col justify-between h-2/5 text-black p-5 rounded-3xl mb-4">
            <h3 className="uppercase text-2xl font-semibold">Simplicity</h3>
            <p>The linear function allows easy liquidity management and prediction of its changes.</p>
          </div>
          <div className="bg-yellow-1 flex-1 lex flex-col justify-between h-2/5 text-black p-5 rounded-3xl mb-4">
            <h3 className="uppercase text-2xl font-semibold">Efficiency</h3>
            <p>Algorithms used in NanosDEX update all statistical characteristics in real-time, ensuring high speed and precision in order execution.</p>
          </div>
        </>
      )
    }
  ];

  const renderTabContent = () => {
    const handleTabNavigation = (direction) => {
      if (direction === 'prev' && activeTab > 0) {
        setActiveTab(activeTab - 1);
      } else if (direction === 'next' && activeTab < tabs.length - 1) {
        setActiveTab(activeTab + 1);
      }
    };
    return (
      <div className="flex tab-background bg-no-repeat justify-between p-10">
        <div className="w-1/2 sm:w-full pr-10">
          <div className=" text-yellow-1 p-10 pb-4 sm:p-4 rounded-3xl h-full flex flex-col justify-between">
            <div>
              <h2 className="text-6xl uppercase font-bold mb-6 sm:text-4xl">
              {tabs[activeTab].header}
              </h2>
              <p className="text-lg mb-10">
              {tabs[activeTab].text}
              </p>
            </div>
            <div className="flex mt-auto space-x-4 sm:hidden">

              <button
                className={`w-16 h-16 border-solid border-2 border-yellow-1 rounded-full flex items-center justify-center ${activeTab === 0 ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer'}`}
                onClick={() => handleTabNavigation('prev')}
                disabled={activeTab === 0}
              >
                &lt;
              </button>
              <button
                className={`w-16 h-16 border-solid border-2 border-yellow-1 rounded-full flex items-center justify-center ${activeTab === tabs.length - 1 ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer'}`}
                onClick={() => handleTabNavigation('next')}
                disabled={activeTab === tabs.length - 1}
              >
                &gt;
              </button>
            </div>
          </div>
        </div>
  
        <div className="w-1/2 sm:w-full h-full pl-10 sm:p-4">
          <div className="h-full text-white rounded-3xl flex flex-col justify-between">
            {tabs[activeTab].content}
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <div id='innovation'>
      {
        innovation != null && innovation.length > 0 ? (
          <div className="px-4 xl:px-v1.5 xxl:px-v1.5 bg-primary">
            <div className='overflow-hidden'>
              <Marquee
                className="uppercase leading-120 overflow-hidden text-140 font-semibold sm:text-7xl whitespace-nowrap mr-10">
                { getValue(detailProps, 'innovationTitle') }
              </Marquee>
            </div>
            <div className="w-2/5 mt-6 ml-auto text-16 md:sm:text-base sm:text-base mb-14 sm:w-full leading-107 tracking-72">
              {/* { getValue(detailProps, 'innovationDescription') } */}
              In the world of decentralized finance (DeFi), innovation plays a key role in enhancing user experience and trading platform efficiency. One such breakthrough is NanosDEX, offering a unique Range Order system that opens new horizons for traders, investors, and liquidity providers.
            </div>


            <div className="flex tab-buttons justify-left mt-8 gap-5">
              <button
                className={`px-4 py-2 ${activeTab === 0 ? 'bg-black text-white' : 'bg-transparent text-black'} px-12 border-black border-2 rounded-3xl border-dashed font-semibold uppercase`}
                onClick={() => setActiveTab(0)}
              >
                Range Orders
              </button>
              <button
                className={`px-4 py-2 ${activeTab === 1 ? 'bg-black text-white' : 'bg-transparent text-black'} px-12 border-black border-2 rounded-3xl border-dashed font-semibold uppercase`}
                onClick={() => setActiveTab(1)}
              >
                Dual Liquidity Position
              </button>
              <button
                className={`px-4 py-2 ${activeTab === 2 ? 'bg-black text-white' : 'bg-transparent text-black'} px-12 border-black border-2 rounded-3xl border-dashed font-semibold uppercase`}
                onClick={() => setActiveTab(2)}
              >
                Innovative Liquidity Model
              </button>
            </div>

            {/* Content Area */}
            <div className="mt-6">
              {renderTabContent()}
            </div>


            <div className="flex sm:flex-col gap-8">
              <div className='w-full flex flex-col gap-7'>
                <div className='flex gap-7 w-full h-3/5 sm:flex-col'>
                    <div className="border-dashed  border-2 mr-20xl border-black sm:w-full sm:mb-5 pt-29xl sm:pt-29 sm:pb-37 pb-37xl pl-50xl pr-43xl sm:px-10 flex flex-col justify-between border-raduis-l sm:sm-border-raduis-l" style={{minHeight: '132px'}}>
                    <div className="flex justify-between sm:flex-col sm:mb-10">
                      <div className="uppercase xxl:text-30 text-4xl sm:text-sm25x leading-120 font-semibold w-3/4 sm:w-full sm:mb-2">
                      Why Should You Try NanosDEX?
                      </div>
                    </div>
                    <div className="text-16 sm:text-base tracking-72 leading-107">
                      NanosDEX combines innovative solutions and ease of use, offering powerful tools for all market participants. Whether you are a trader seeking flexibility, an investor looking for transparency and fairness, or a liquidity provider aiming to maximize returns, NanosDEX has everything you need.
                    </div>
                  </div>
                  <div className="bg-black relative md:hidden sm:hidden w-full pt-1/3-2" style={{borderRadius: '10vw'}}>
                    <img src={svg} className='absolute object-contain' style={{left: '29%', top:'33%', width:'40%'}}>
                    </img>
                  </div>
                </div>
                <div className="border-dashed border-2 border-black w-full mr-10xl sm:w-full sm:mb-5 relative pt-3-12 border-raduis-l sm:sm-border-raduis-l sm:h-screen sm:hidden" style={{minHeight: '200px', maxHeight: '370px'}}>
                  <div className='absolute top-0 flex flex-col justify-between pt-5 pb-35xl-1 px-8 sm:pt-29 sm:pb-37 sm:px-10 w-full '>
                    <div className="flex justify-between sm:flex-col sm:mb-10">
                      <div className="uppercase font-semibold mb-10 leading-120 xxl:text-25 sm:text-sm25x text-25xl w-3/5 sm:w-full sm:mb-2">
                        Join Nanos DEX
                      </div>
                    </div>
                    <div className='flex justify-between sm:flex-col sm:gap-10'>
                      <div className="text-16 sm:text-base tracking-72 leading-107 mr-36 lg:mr-0 md:mr-0 sm:lg:mr-0">
                        Try NanosDEX today and become part of a new era in decentralized trading!
                        <br></br>
                        <br></br>
                        <a className='underline' href='mailto:welcome@nanospay.ai'>welcome@nanospay.ai</a>   
                      </div>
                      <div className="text-16 sm:text-base tracking-72 leading-107 lg:mr-0 md:mr-0 sm:lg:mr-0">
                        NanosDEX is not just another decentralized exchange. It is a platform that opens new opportunities for all market participants, providing flexibility, efficiency, and fairness.  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex-col justify-between w-3/5 flex gap-3 sm:w-full'>
                <div className="bg-black flex-1 text-yellow-1 p-6 px-10 rounded-xxl flex justify-between ">
                  <div>
                    <h2 className="text-2xl font-semibold uppercase mb-4">
                    for Traders
                    </h2>
                    <ul className='list-disc pl-6'>
                      <li className="text-base leading-7">
                      Flexibility and control with Range Orders
                      </li> 
                      <li className="text-base leading-7">
                      Continuous execution and risk reduction
                      </li> 
                    </ul>
                  </div>
                </div>

                <div className="bg-black flex-1 text-yellow-1 p-6 px-10 rounded-xxl flex justify-between">
                  <div>
                    <h2 className="text-2xl font-semibold uppercase mb-4">
                    for Investors
                    </h2>
                    <ul className='list-disc pl-6'>
                      <li className="text-base leading-7">
                      Transparent and fair revenue distribution
                      </li> 
                      <li className="text-base leading-7">
                      Efficient asset management
                      </li> 
                    </ul>
                  </div>
                </div>

                <div className="bg-black flex-1 text-yellow-1 p-6 px-10 rounded-xxl flex justify-between">
                  <div>
                    <h2 className="text-2xl font-semibold uppercase mb-4">
                    for Liquidity Providers
                    </h2>
                    <ul className='list-disc pl-6'>
                      <li className="text-base leading-7">
                      Maximized returns through a common dual position
                      </li> 
                      <li className="text-base leading-7">
                      Adaptability and optimization of liquidity
                      </li> 
                    </ul>
                  </div>
                </div>

                <div className="border-dashed border-2 border-black w-full mr-10xl sm:w-full sm:mb-5 relative pt-3xl-1 border-raduis-l sm:sm-border-raduis-l sm:h-screen hidden sm:block sm:mt-8" style={{minHeight: '200px', maxHeight: '370px'}}>
                  <div className='absolute top-0 flex flex-col justify-between pt-5 pb-35xl-1 px-8 sm:pt-29 sm:pb-37 sm:px-10 w-full '>
                    <div className="flex justify-between sm:flex-col sm:mb-10">
                      <div className="uppercase font-semibold mb-10 leading-120 xxl:text-25 sm:text-sm25x text-25xl w-3/5 sm:w-full sm:mb-2">
                        Join Nanos DEX
                      </div>
                    </div>
                    <div className='flex justify-between sm:flex-col sm:gap-10'>
                      <div className="text-16 sm:text-base tracking-72 leading-107 mr-36 lg:mr-0 md:mr-0 sm:lg:mr-0">
                        Try NanosDEX today and become part of a new era in decentralized trading!
                        <br></br>
                        <br></br>
                        <a className='underline' href='mailto:welcome@nanospay.ai'>welcome@nanospay.ai</a>   
                      </div>
                      <div className="text-16 sm:text-base tracking-72 leading-107 lg:mr-0 md:mr-0 sm:lg:mr-0">
                        NanosDEX is not just another decentralized exchange. It is a platform that opens new opportunities for all market participants, providing flexibility, efficiency, and fairness.  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="flex sm:flex-col">
              <div className="border-dashed w-1/3-0 border-2 mr-20xl border-black w-1/2 sm:w-full sm:mb-5 pt-29xl sm:pt-29 sm:pb-37 pb-37xl pl-50xl pr-43xl sm:px-10 flex flex-col justify-between border-raduis-l sm:sm-border-raduis-l" style={{minHeight: '132px'}}>
                <div className="flex justify-between sm:flex-col sm:mb-10">
                  <div className="uppercase xxl:text-25 text-25xl sm:text-sm25x leading-120 font-semibold w-3/4 sm:w-full sm:mb-2">
                    { innovation[0].attributes.title }
                  </div>
                  <div className="algin-top">
                    <img src={vector}>
                    </img>
                  </div>
                </div>
                <div className="text-16 sm:text-base tracking-72 leading-107">
                  { innovation[0].attributes.description }
                </div>
              </div>
              <div className="bg-black relative md:hidden sm:hidden w-1/3-2 pt-1/3-2" style={{borderRadius: '10vw'}}>
                <img src={svg} className='absolute object-contain' style={{left: '29%', top:'33%', width:'40%'}}>
                </img>
              </div>
              <div className="border-dashed w-1/3-4 border-2 ml-20xl md:ml-0 sm:ml-0 border-black pt-29xl pb-37xl pl-50xl pr-43xl sm:pt-29 sm:pb-37 sm:px-10 md:w-2/3-2 sm:w-full sm:mb-5 flex flex-col justify-between border-raduis-l sm:sm-border-raduis-l" style={{minHeight: '132px'}}>
                <div className="flex justify-between sm:flex-col sm:mb-10">
                  <div className="uppercase leading-120 font-semibold xxl:text-25 sm:text-sm25x text-25xl w-3/5 sm:mb-2">
                    { innovation[1].attributes.title }     
                  </div>
                  <div className="algin-top">
                    <img src={vector}>
                    </img>
                  </div>
                </div>
                <div className="text-16 sm:text-base tracking-72 leading-107 mr-14 lg:mr-0 md:mr-0 sm:lg:mr-0">
                  { innovation[1].attributes.description }     
                </div>
              </div>
            </div>
            <div className="flex mt-4 mb-20 sm:flex-col sm:mt-0">
              <div className="border-dashed border-2 border-black w-7/12 mr-10xl sm:w-full sm:mb-5 relative pt-3xl-1 border-raduis-l sm:sm-border-raduis-l sm:min-height-xl" style={{minHeight: '132px'}}>
                <div className='absolute top-0 flex flex-col justify-between pt-24xl-1 pb-35xl-1 px-48xl-1 sm:pt-29 sm:pb-37 sm:px-10 w-full h-full'>
                  <div className="flex justify-between sm:flex-col sm:mb-10">
                    <div className="uppercase font-semibold leading-120 xxl:text-25 sm:text-sm25x text-25xl w-3/5 sm:w-full sm:mb-2">
                      { innovation[2].attributes.title }     
                    </div>
                    <div className="algin-top">
                      <img src={vector}>
                      </img>
                    </div>
                  </div>
                  <div className="text-16 sm:text-base tracking-72 leading-107 mr-36 lg:mr-0 md:mr-0 sm:lg:mr-0">
                    { innovation[2].attributes.description }     
                  </div>
                </div>
              </div>
              <div className="border-dashed border-2 border-black pt-29xl pb-37xl pl-50xl pr-43xl sm:pt-29 sm:pb-37 sm:px-10 ml-10xl sm:ml-0 sm:w-full sm:mb-5 flex flex-col justify-between border-raduis-l sm:sm-border-raduis-l" style={{minHeight: '132px'}}>
                <div className="flex justify-between sm:flex-col sm:mb-10 ">
                  <div className="uppercase leading-120 font-semibold xxl:text-25 sm:text-sm25x text-25xl w-3/5 sm:w-full sm:mb-2">
                    { innovation[3].attributes.title }     
                  </div>
                  <div className="algin-top">
                    <img src={vector}>
                    </img>
                  </div>
                </div>
                <div className="text-16 sm:text-base tracking-72 leading-107 mr-20 lg:mr-0 md:mr-0 sm:lg:mr-0">
                  { innovation[3].attributes.description }     
                </div>
              </div>
            </div> */}
            <div className="flex justify-center pb-20 hidden">
              <div className="px-14 py-5 uppercase leading-160 font-semibold bg-black text-white" style={{borderRadius:'5rem'}}>
                  Load More
              </div>
            </div>
          </div>
        ) : (
          <></>
        )
      }
    </div>
  )
}

export default Innovation