import React from "react";
import PropTypes from "prop-types";
import Vector from "../../images/Vector-3.svg";

const LegalPageContent = ({ companyName, folderName }) => {

    const pdfFiles = [
        { fileName: 'Privacy Policy', fileUrl: require(`../../pdf/${folderName}/privacy_policy.pdf`).default },
        { fileName: 'Cookie Policy', fileUrl: require(`../../pdf/${folderName}/cookie_policy.pdf`).default },
        { fileName: 'Security & Data Retention Policy', fileUrl: require(`../../pdf/${folderName}/security_data_retention_policy.pdf`).default },
        { fileName: 'Terms & Conditions', fileUrl: require(`../../pdf/${folderName}/terms_conditions.pdf`).default },
        { fileName: 'Corporate Information', fileUrl: require(`../../pdf/${folderName}/corporate_information.pdf`).default },
      ];

    return (
        <div className="max-w-xs">
            <div className="uppercase text-25 sm:text-xl md:text-lg font-bold mb-6">{companyName}</div>
            {pdfFiles.map((pdf, index) => (
                <div key={index} className="flex items-center w-max justify-between mt-2">
                <div className='text-x17 leading-148 font-semibold'>
                    <a onClick={() => window.open(pdf.fileUrl)} className="cursor-pointer" target="_blank">
                    {pdf.fileName}
                    </a>
                </div>
                <div>
                    <img className="w-3 my-auto ml-6" src={Vector} alt="" />
                </div>
                </div>
            ))}
        </div>
    );
};

LegalPageContent.propTypes = {
    companyName: PropTypes.string.isRequired,
    folderName: PropTypes.string.isRequired,
    pdfFiles: PropTypes.arrayOf(
      PropTypes.shape({
        fileName: PropTypes.string.isRequired,
        fileUrl: PropTypes.string.isRequired,
      })
    ).isRequired,
};
  
export default LegalPageContent;