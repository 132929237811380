import React from 'react'
import Vector from '../../images/Vector-3.svg'

import { useDetailProps } from '../../detailProvider';

import { getValue } from '../../utils';
import FooterBottom from '../FooterBottom';

const Footer = () => {

  const detailProps = useDetailProps();

  return (
    <div id='contact'>
      <div className="bg-primary px-4 xl:px-v1.5 xxl:px-v1.5 pt-5 mb-37">
        <div className="flex sm:flex-col items-center sm:items-start">
          <div className="leading-120 uppercase text-41 w-3/5 font-semibold sm:w-full sm:text-xl">
            { getValue(detailProps, 'contactTitle') }
          </div>
          <div className="flex flex-col items-center sm:items-start sm:mt-12">
            {/* <div className="flex items-center w-24 justify-between">
              <div className='text-x17 leading-148 font-semibold'>
                Discord
              </div>
              <div>
                <img className="w-2" src={Vector}>
                </img>
              </div>
            </div> */}
            {/* <div className="flex items-center w-24 justify-between">
              <div className='text-x17 leading-148 font-semibold'>
                <a href="https://twitter.com/">Twitter / X</a> 
              </div>
              <div>
                <img className="w-2" src={Vector}>
                </img>
              </div>
            </div>
            <div className="flex items-center w-24 justify-between">
              <div className='text-x17 leading-148 font-semibold'>
              <a href="https://telegram.org/">Telegram</a>  
              </div>
              <div>
                <img className="w-2" src={Vector}>
                </img>
              </div>
            </div> */}
          </div>
        </div>
        <a href={`mailto:${getValue(detailProps, 'email') }`} className="flex items-end text-130 sm:text-2xl text-with-underline md:md-text-with-underline sm:ms-text-with-underline truncate pt-10 font-medium">
          { getValue(detailProps, 'email') }
        </a>
        <FooterBottom></FooterBottom>
      </div>
    </div>
  )
}

export default Footer