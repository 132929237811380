import React from 'react';
import { Outlet, Link } from "react-router-dom";
import { useDetailProps } from '../../detailProvider';
import arrow from '../../images/arrow_down.svg';
import { getValue } from '../../utils';

const FundsSection = ({funds}) => {

  const detailProps = useDetailProps();

  return (
    <div id='funds'>
        <div className="holder pb-36 pt-20">
            <div className="flex justify-between pb-20 text-100 sm:flex-wrap sm:text-3xl">
                <div className="uppercase font-bold">Nanos</div>
                <div className="uppercase font-bold">Trading</div>
                <div className="uppercase font-bold">Funds</div>
            </div>
            <div className='border-2 border-dashed border-black border-raduis-l  p-8 m-5'>
                <Link to="/funds/0">
                    <div className='text-sm border-2 border-black rounded-3xl px-2 w-max mb-4'>Funding Open</div>
                    <div className='flex justify-between mb-6 md:flex-wrap'>
                        <div className='max-w-lg'>
                            <div className="text-normal uppercase text-5xl font-bold mb-6">
                                Liquidity Delta Strategy
                            </div>
                            <div className="text-normal">
                                Fund utilizes artificial intelligence and machine learning algorithms to make informed investment decisions in the cryptocurrency market.
                            </div>
                        </div>
                        <div className='grid grid-cols-2 md:mt-5'>
                            <div className="text-normal">Liquidity:</div>
                            <div className="text-normal font-bold">Daily and assets: 1INCH, ACE, ACH, ADA, AEVO, AGI, ALT, APE, APEX, API3, APT, AR, ARB, ARK, ARKM, ASTR, ATOM, AVAX, AXS, BAL, BCH, BEAM, BLUR, BNB, BTC, C98, CELO, CFX, CHZ, COMP, COTI, CRV, DOGE, DOT, DUSK, DYM, ENA, ENS, EOS, ETC, ETH, ETHW, FET, FIDA, FIL, FLOKI, FTM, GALA, GAS, GLM, GMX, GRT, HBAR, HOOK, ICP, ID, IMX, INJ, IO, IOTX, JTO, JUP, KNC, LDO, LINK, LTC, MAGIC, MASK, MINA, MKR, MNT, NEAR, NEO, ONDO, OP, ORDI, PENDLE, PYTH, RENDER, RUNE, SEI, SHIB, SNX, SOL, SSV, STRK, STX, SUI, SUSHI, TIA, TON, WIF, WLD, XRP, XTZ, ZETA, ZK</div>
                            
                            <div className="text-normal">Launch:</div>
                            <div className="text-normal font-bold">January 2024</div>

                            <div className="text-normal">Minimum Investment:</div>
                            <div className="text-normal font-bold">$100,000</div>

                            <div className="text-normal">Investor Type:</div>
                            <div className="text-normal font-bold">Accredited</div>

                            <div className="text-normal">Subscriptions:</div>
                            <div className="text-normal font-bold">Daily, Upon One Day’s Notice</div>

                            <div className="text-normal">Redemptions:</div>
                            <div className="text-normal font-bold">Daily, Upon One Day’s Notice</div>
                        </div>
                    </div>
                    <div className='ml-auto w-max'>
                        <div className='flex text-base font-bold gap-4'>ABOUT THE FUND<img className='h-3.5 my-auto' src={arrow} alt="" /></div>    
                    </div>
                </Link>
            </div>
            <div className='border-2 border-dashed border-black border-raduis-l  p-8 m-5'>
            <Link to="/funds/1">
                    <div className='text-sm border-2 border-black rounded-3xl px-2 w-max mb-4'>Funding Open</div>
                    <div className='flex justify-between mb-6 md:flex-wrap'>
                        <div className='max-w-lg'>
                            <div className="text-normal uppercase text-5xl font-bold mb-6">
                                Liquidity Delta Strategy Stablecoin
                            </div>
                            <div className="text-normal">
                            The fund’s strategy involves leveraging advanced data analytics and computational models to analyze market trends, identify trading opportunities, and execute trades automatically.
                            <br></br><br></br>
                            To enhance equity protection, the fund focuses on stable pairs with consistent price behavior. This approach may lead to slightly lower immediate returns but significantly reduces risk by minimizing exposure to extreme volatility, creating a more secure investment environment.
                            <br></br><br></br>
                            This strategy promotes steady profit growth while significantly reducing the potential for major losses, providing investors with a balanced risk-reward profile focused on stability. Additionally, the fund's emphasis on maintaining liquidity enables quick market adjustments, ensuring efficient trade execution with minimal slippage.
                            <br></br><br></br>
                            Liquidity Delta Stable Strategy Crypto Fund combines cutting-edge technology with a focus on stability and risk management, positioning itself as a resilient investment vehicle capable of delivering good profits while protecting equity.
                            </div>
                        </div>
                        <div className='grid grid-cols-2 sm:grid-cols-2 md:mt-5' style={{ height: "max-content"}}>
                            <div className="text-normal">Liquidity:</div>
                            <div className="text-normal font-bold">Daily and assets: AAVE, ACE, APE, ASTR, API3, APT, AR, ARB, ASTR, ATOM, BABYDOGE, BCH, CKB, CHZ, COTI, DOT, DYDX, ENJ, ENS, FIL, FTM, GALA, GLM, GRT, FLOKI, IMX, INJ, LINK, LPT, MEME, MINA, MKR, ORDI, PHB, PYTH, RSR, RUNE, SNX, SSV, TIA, VANRY, WLD</div>

                            <div className="text-normal">Launch:</div>
                            <div className="text-normal font-bold">January 2024</div>

                            <div className="text-normal">Minimum Investment:</div>
                            <div className="text-normal font-bold">$100,000</div>

                            <div className="text-normal">Investor Type:</div>
                            <div className="text-normal font-bold">Accredited</div>

                            <div className="text-normal">Subscriptions:</div>
                            <div className="text-normal font-bold">Daily, Upon One Day’s Notice</div>

                            <div className="text-normal">Redemptions:</div>
                            <div className="text-normal font-bold">Daily, Upon One Day’s Notice</div>
                        </div>
                    </div>
                    <div className='ml-auto w-max'>
                        <div className='flex text-base font-bold gap-4'>ABOUT THE FUND<img className='h-3.5 my-auto' src={arrow} alt="" /></div>    
                    </div>
                </Link>
            </div>
            
            {/* <div className='border-2 border-dashed border-black border-raduis-xl py-8 px-5 m-5'>
                <Link to="/funds/1" >
                    <div className='text-sm text-yellow-1 bg-black border-2 border-black rounded-3xl px-2 w-max mb-4'>Funding Closed</div>
                    <div className='flex justify-between mb-6 md:flex-wrap'>
                        <div className='max-w-2xl'>
                            <div className="text-normal uppercase text-5xl font-bold mb-6 uppercase">
                                Levels AI Algorithm Trading Crypto Fund
                            </div>
                            <div className="text-normal">
                                Fund utilizes artificial intelligence and machine learning algorithms to make informed investment decisions in the cryptocurrency market.
                            </div>
                        </div>
                        <div className='grid grid-cols-2 md:mt-5'>
                            <div className='flex flex-col'>
                                <div className="text-normal">Liquidity:</div>
                                <div className="text-normal">Launch:</div>
                                <div className="text-normal">Minimum Investment:</div>
                                <div className="text-normal">Investor Type:</div>
                                <div className="text-normal">Subscriptions:</div>
                                <div className="text-normal">Redemptions:</div>
                            </div>
                            <div className='flex flex-col'>
                                <div className="text-normal font-bold">Daily and assets: BTC/USDT/ETH/XRP/USDC</div>
                                <div className="text-normal font-bold">January 2024</div>
                                <div className="text-normal font-bold">$100,000</div>
                                <div className="text-normal font-bold">Accredited & Non Accredited</div>
                                <div className="text-normal font-bold">Daily, Upon One Day’s Notice</div>
                                <div className="text-normal font-bold">Monthly, Upon One Day’s Notice</div>
                            </div>
                        </div>
                    </div>
                    <div className='ml-auto w-max'>
                        <div className='flex text-base font-bold gap-4'>ABOUT THE FUND<img className='h-3.5 my-auto' src={arrow} alt="" /></div>
                    </div>
                </Link>
            </div> */}

            {/* 
            <div className='border-b-2 border-dashed border-black-20 py-8 px-5'>
                <div className='text-sm text-yellow-1 bg-black border-2 border-black rounded-3xl px-2 w-max mb-4'>Funding Closed</div>
                <div className='flex justify-between mb-6 md:flex-wrap'>
                    <div className='max-w-2xl'>
                        <div className="text-normal uppercase text-5xl font-bold mb-6">
                            Levels AI Algorithm Trading Crypto Fund
                        </div>
                        <div className="text-normal">
                            Fund utilizes artificial intelligence and machine learning algorithms to make informed investment decisions in the cryptocurrency market.
                        </div>
                    </div>
                    <div className='grid grid-cols-2 md:mt-5'>
                        <div className='flex flex-col'>
                            <div className="text-normal">Liquidity:</div>
                            <div className="text-normal">Launch:</div>
                            <div className="text-normal">Minimum Investment:</div>
                            <div className="text-normal">Investor Type:</div>
                            <div className="text-normal">Subscriptions:</div>
                            <div className="text-normal">Redemptions:</div>
                        </div>
                        <div className='flex flex-col'>
                            <div className="text-normal font-bold">Daily and assets: BTC/USDT/ETH</div>
                            <div className="text-normal font-bold">January 2024</div>
                            <div className="text-normal font-bold">$100,000</div>
                            <div className="text-normal font-bold">Accredited</div>
                            <div className="text-normal font-bold">Daily, Upon One Day’s Notice</div>
                            <div className="text-normal font-bold">Daily, Upon One Day’s Notice</div>
                        </div>
                    </div>
                </div>
                <div className='ml-auto w-max'>
                <Link className='flex text-base font-bold gap-4' to="/funds/3">ABOUT THE FUND<img className='h-3.5 my-auto' src={arrow} alt="" /></Link>
                </div>
            </div>
            <div className='border-b-2 border-dashed border-black-20 py-8 px-5'>
                <div className='text-sm text-yellow-1 bg-black border-2 border-black rounded-3xl px-2 w-max mb-4'>Funding Closed</div>
                <div className='flex justify-between mb-6 md:flex-wrap'>
                    <div className='max-w-2xl'>
                        <div className="text-normal uppercase text-5xl font-bold mb-6">
                            Levels AI Algorithm Trading Crypto Fund
                        </div>
                        <div className="text-normal">
                            Fund utilizes artificial intelligence and machine learning algorithms to make informed investment decisions in the cryptocurrency market.
                        </div>
                    </div>
                    <div className='grid grid-cols-2 md:mt-5'>
                        <div className='flex flex-col'>
                            <div className="text-normal">Liquidity:</div>
                            <div className="text-normal">Launch:</div>
                            <div className="text-normal">Minimum Investment:</div>
                            <div className="text-normal">Investor Type:</div>
                            <div className="text-normal">Subscriptions:</div>
                            <div className="text-normal">Redemptions:</div>
                        </div>
                        <div className='flex flex-col'>
                            <div className="text-normal font-bold">Daily and assets: BTC/USDT/ETH</div>
                            <div className="text-normal font-bold">January 2024</div>
                            <div className="text-normal font-bold">$100,000</div>
                            <div className="text-normal font-bold">Accredited</div>
                            <div className="text-normal font-bold">Daily, Upon One Day’s Notice</div>
                            <div className="text-normal font-bold">Daily, Upon One Day’s Notice</div>
                        </div>
                    </div>
                </div>
                <div className='ml-auto w-max'>
                <Link className='flex text-base font-bold gap-4' to="/funds/4">ABOUT THE FUND<img className='h-3.5 my-auto' src={arrow} alt="" /></Link>
                </div>
            </div> */}

  
            {/* <div className='text-xl py-4 px-4 w-max mx-auto rounded-full bg-black text-yellow-1 cursor-pointer mt-10'>
            <Link to="/funds">EXPLORE ALL FUNDS</Link>
            </div> */}
        </div>
    </div>
  )
}

export default FundsSection