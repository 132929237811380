import React, { useState, useEffect } from "react";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import SignIn from "../components/SignIn";
import FundsSection from "../components/FundsSection";
import Footer from '../components/FooterSection';

import { DetailPropsProvider } from "../detailProvider"

import axios from "axios";
import qs from "qs";

const serverUrl = process.env.REACT_APP_STRAPI_SERVER;
const token = process.env.REACT_APP_STRAPI_TOKEN;

function Funds() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSignInOpen, setIsSignInOpen] = useState(false);

  const [config, setConfig] = useState([]);
  const [services, setService] = useState([]);
  const [innovation, setInnovation] = useState([]);  
  const [statistics, setStatistic] = useState([]);  

  const query = qs.stringify({ 
    populate: '*',
    fields: '*',
    publicationState: 'live',
    locale: ['en'],
  }, {
    encodeValuesOnly: true, // prettify url
  });

  const axiosConfig = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const getAllConfig = async () => {
    try {
      // fetch config from strapi
      const res = await axios.get(`${serverUrl}/api/configs?${query}`,axiosConfig);
      // get result from nested object destructuring
      const {
        data: { data },
      } = res;
      setConfig(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getAllService = async () => {
    try {
      // fetch config from strapi
      const res = await axios.get(`${serverUrl}/api/services?${query}`,axiosConfig);
      // get result from nested object destructuring
      const {
        data: { data },
      } = res;
      setService(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getAllInnovation = async () => {
    try {
      // fetch config from strapi
      const res = await axios.get(`${serverUrl}/api/innovations?${query}`,axiosConfig);
      // get result from nested object destructuring
      const {
        data: { data },
      } = res;
      setInnovation(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getAllStatistic = async () => {
    try {
      // fetch config from strapi
      const res = await axios.get(`${serverUrl}/api/statistics?${query}`,axiosConfig);
      // get result from nested object destructuring
      const {
        data: { data },
      } = res;
      setStatistic(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getAllConfig();
    getAllService();
    getAllInnovation();
    getAllStatistic();
  }, []);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  const toggleSignIn = () => {
    setIsSignInOpen((prev) => !prev);
  };

  return (
    <DetailPropsProvider props={config}>
      <div className="flex justify-center bg-primary">
        <div className="w-full items-center">
          <Sidebar isOpen={isOpen} toggle={toggle} />
          <SignIn isOpen={isSignInOpen} toggle={toggleSignIn} />
          <Navbar toggle={toggle} toggleSignIn={toggleSignIn}/>
          <FundsSection></FundsSection>
          <Footer/>
        </div>
      </div>
    </DetailPropsProvider>
  );
}

export default Funds;
