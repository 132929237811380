import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import logo from "../../images/Logo(white-small).svg"
import { Link as LinkRouter } from 'react-router-dom';

const serverUrl = process.env.REACT_APP_STRAPI_SERVER;

const Service = ({ services }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1520 },
      items: 2.7
    },
    desktop: {
      breakpoint: { max: 1520, min: 1230 },
      items: 2.7
    },
    tablet: {
      breakpoint: { max: 1230, min: 860 },
      items: 2.4
    },
    largeMobile: {
      breakpoint: { max: 860, min: 710 },
      items: 2
    },
    middleMobile: {
      breakpoint: { max: 710, min: 453 },
      items: 1.5
    },
    mobile: {
      breakpoint: { max: 453, min: 0 },
      items: 1.1
    }
  };

  // const services = [
  //   {
  //     id : 0,
  //     title : 'MONEY TRANSFER',
  //     description : 'Swift and secure cross-border transactions made possible with cutting-edge technology and encryption protocols.',
  //     button1 : 'SWIFT',
  //     button2 : 'SMARTCONTRACTS',
  //     button3 : 'SECURE'
  //   },
  //   {
  //     id : 1,
  //     title : 'Dealing in Virtual Currencies',
  //     description : 'Easily buy, sell, and trade various virtual currencies, catering to diverse investment strategies and preferences.',
  //     button1 : 'WEB3',
  //     button2 : 'TOKENS',
  //     button3 : 'NFT'
  //   },
  //   {
  //     id : 2,
  //     title : 'Payment Services Provider (PSP)',
  //     description : 'Trust Nanos Payments as a reliable intermediary, enabling businesses to accept digital payments, including virtual currencies, and expand their payment options.',
  //     button1 : 'WEB3',
  //     button2 : 'SMARTCONTRACTS',
  //     button3 : 'MOBILE'
  //   },
  //   {
  //     id : 3,
  //     title : 'Virtual Assets Services Provider',
  //     description : 'Optimize your digital wealth with custody solutions, asset management, and portfolio diversification strategies.',
  //     button1 : 'WEB3',
  //     button2 : 'TOKENS',
  //     button3 : 'NFT'
  //   },
  //   {
  //     id : 4,
  //     title : 'Crypto Exchange and Wallet Service',
  //     description : 'Optimize your digital wealth with custody solutions, asset management, and portfolio diversification strategies.',
  //     button1 : 'WALLET',
  //     button2 : 'TOKENS',
  //     button3 : 'EXCHANGE'
  //   }
  // ];

  const renderItem = (id, service, isLast) => {
    const backgroundImage = `url(${service.image.data.attributes.url})`;
    return (
      <div key={ id } className={`image-container relative bg-no-repeat bg-contain text-white carousel-item xl:xl-carousel-item xxl:xl-carousel-item lg:lg-carousel-item sm:sm-carousel-item ${isLast ? '' : ''}`} 
        style={{backgroundSize: '100% 100%', paddingTop:'124.5%', minHeight:'400px', backgroundImage: backgroundImage}}>
          <div className='absolute top-0 w-full h-full flex flex-col justify-between pt-v3 pl-v3 sm:pt-6 sm:pl-6'>
            <div className="flex flex-col justify-between text-xl pr-10 h-full text-yellow-1">
              <div className="text-35 leading-normal uppercase lg:text-3xl md:text-2xl sm:text-xl  whitespace-pre-line font-semibold">
                { service.title }
              </div>
              <div className='pb-v4 lg:pb-10 md:pb-8 sm:pb-5 text-20 leading-140 md:text-sm sm:text-xs'>
                { service.description }
              </div>
            </div>
            <div className="flex justify-between items-end">
              <div className="mb-6 xl:mb-v12 xl:w-1/20-0 xxl:mb-v12 xxl:w-1/20-0">
                <img src={logo} className='sm:h-3.5 xl:w-full xxl:w-full'/>
              </div>
              <div className="flex justify-end text-12 xl:text-12 xxl:text-12 md:text-xsl lg:text-xsl sm:text-xxs font-semibold">
                <div className="border-2 px-4xl-1 py-2xl-1 uppercase border-gray-250 text-black ml-1 rounded-xxl">
                  { service.link1 }
                </div>
                <div className="border-2 px-4xl-1 py-2xl-1 uppercase border-gray-250 text-black ml-1 rounded-xxl">
                  { service.link2 }
                </div>
                <div className="border-2 px-4xl-1 py-2xl-1 uppercase border-gray-250 text-black ml-1 rounded-xxl">
                  { service.link3 }
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }

  return (
    <div id='service'>
      <div className="bg-primary px-4 xl:px-v1.5 xxl:px-v1.5 mt-20">
        <div className="flex justify-between pb-20 text-100 md:text-6xl sm:text-3xl-1 leading-120">
          <div className="uppercase font-bold">What</div>
          <div className="uppercase font-bold mr-14">We</div>
          <div className="uppercase font-bold">Do</div>
        </div>
      </div>
      <Carousel 
        responsive={responsive} className='mb-48'
        swipeable={false}
        draggable={false}
        ssr={true}
        arrows={true}
        showDots={false}
        autoPlay={false}
        infinite={false}
        keyBoardControl={true}
        itemClass=""
      >
        {
          services.map((service, index) => {
            return (renderItem(service.id, service.attributes, index == services.length - 1))
          })    
        }
      </Carousel>
    </div>
  )
}

export default Service

