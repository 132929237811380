import React, { createContext, useContext } from 'react';

// Create a context
const DetailPropsContext = createContext();

// Create a provider component to wrap your application
export function DetailPropsProvider({ children, props }) {
  return (
    <DetailPropsContext.Provider value={props}>
      {children}
    </DetailPropsContext.Provider>
  );
}

// Create a custom hook to access the common props
export function useDetailProps() {
  return useContext(DetailPropsContext);
}