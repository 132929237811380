import React from 'react'
import logo from '../../images/logo_big.svg';
import vector from '../../images/Vector-1.svg';
import mask from '../../images/edge.png';

import { useDetailProps } from '../../detailProvider';

import { getValue } from '../../utils';

const About = () => {

  const detailProps = useDetailProps();

  return (
    <div id="about"> 
    <div className="bg-primary pb-24">
      <div className="flex justify-center items-center px-4 xl:px-v1.5 xxl:px-v1.5 relative mb-32 md:mb-24 sm:mb-16">
        <div className="image-container bg-full bg-no-repeat bg-left w-full rounded-xl flex flex-col justify-between text-black bg-bgImage xsm:bg-smImage rounded-d4 xl:rounded-v2 xxl:rounded-v2" 
          style={{paddingTop:"50%", minHeight: '45rem'}}>
          <div className='absolute top-0 h-full flex flex-col justify-between'>
            <img src={mask} className='absolute max-w-sm sm:w-1/2 h-28 object-fill custom-mask' />
            <div className='sm:text-xs text-sm text-right mr-10 z-0 tracking-63 leading-107'>
             <a className='cursor-pointer' href="mailto:welcome@nanospay.ai">welcome@nanospay.ai</a>
            </div>
            <div className="px-10 text-white flex justify-between sm:flex-col sm:px-5">
              <div className="flex items-end mb-14">
                <div className="flex items-start">
                  <img className="pt-1 pr-2" src={logo}>
                  </img>
                </div>
              </div>
              <div className="w-1/3-1 sm:w-full text-16 leading-107 text-yellow-1 mt-3 font-normal mb-14 md:text-35 sm:text-60" style={{letterSpacing: '-0.72px'}}>
                Welcome to Nanos Payments, where we are leading the way in revolutionizing the digital payments and crypto finance landscape. Join us as we empower individuals and businesses with cutting-edge technology and innovative solutions, making your journey through the world of virtual assets seamless and secure.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 xl:px-v1.5 xxl:px-v1.5">
        <div className="text-base sm:text-xs font-semibold mb-1.5 sm:mb-3.5">
          About Holding
        </div>
        <div className="flex justify-center sm:flex-col md:flex-col">
          <div className="flex flex-col justify-between sm:flex-row sm:mb-12 md:flex-row md:mb-10">
            <div className="uppercase text-41 leading-120 sm:text-xl font-semibold pr-24 lg:pr-14 md:pr-0 sm:pr-0">
              { getValue(detailProps, 'holdingTitle') }
            </div>
            <div>
              <img src={vector}/>
            </div>
          </div>
          <div className="w-11/12 sm:w-full md:w-full pr-20 sm:pr-0 md:pr-0">
            <div className="text-41 sm:text-xl leading-107 tracking-164 sm:tracking-0 mb-10 font-normal">
              { getValue(detailProps, 'holdingDescription') }
            </div>
            <div className="w-8/12 tracking-72 sm:w-full md:w-full leading-107 text-16 lg:text-sm md:text-sm sm:text-xs">
              { getValue(detailProps, 'holdingComment') }
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default About