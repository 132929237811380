import React from "react";
import { animateScroll as scroll } from "react-scroll";
import { useHistory, useLocation } from "react-router-dom";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink
} from "./SidebarElements";

function Sidebar({ isOpen, toggle }) {
  const pdf = require(`../../pdf/nanos_whitepaper.pdf`).default
  const scrollProps = {
    activeClass: "border-solid border-b-4 border-green-500",
    smooth: true,
    duration: 500,
    spy: true,
    exact: "true",
    offset: -80,
  };
  const location = useLocation();
  const history = useHistory();

  const scrollToSection = (sectionId) => {
    // If on the funds page (main or detail), go back to the home page and scroll to the section
    if (location.pathname.startsWith("/funds") || location.pathname.startsWith("/legal")) {
      history.push("/");
      scroll.scrollTo(sectionId, scrollProps);
    } else {
    }
  };

  return (
    <SidebarContainer isOpen={isOpen}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="about" onClick={() => { toggle(); scrollToSection("about"); }}>
            ABOUT
          </SidebarLink>
          <SidebarLink to="service" onClick={() => { toggle(); scrollToSection("service"); }}>
            SERIVCES
          </SidebarLink>
          <SidebarLink to="platform" onClick={() => { toggle(); scrollToSection("platform"); }}>
            PLATFORM
          </SidebarLink>
          <SidebarLink to="funds" onClick={() => { toggle(); scrollToSection("funds"); }}>
            FUNDS
          </SidebarLink>
          <SidebarLink to="innovation" onClick={() => { toggle(); scrollToSection("innovation"); }}>
            INNOVATION
          </SidebarLink>
          <SidebarLink to="token" onClick={() => { toggle(); scrollToSection("token"); }}>
            TOKEN
          </SidebarLink>
          <SidebarLink to="contact" onClick={() => { toggle(); scrollToSection("contact"); }}>
            CONTACTS
          </SidebarLink>
          <SidebarLink onClick={() => window.open(pdf)} target="_blank">
            WHITEPAPER  
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
}

export default Sidebar;
