import React,{ useState } from 'react';
import computer from '../../images/Computer.png';
import svg from '../../images/svg-4.svg';
import mask from '../../images/mask.png';
import maskSmall from '../../images/mask-smail.png';
import folder from '../../images/folder.svg';
import btc from '../../images/BTC.svg';
import eth from '../../images/ETH.svg';
import usdt from '../../images/USDT.svg';
import arrow from '../../images/arrow_down.svg';
import Carousel from 'react-multi-carousel';
import { Link } from "react-router-dom";
import { useDetailProps } from '../../detailProvider';

import { getValue } from '../../utils';
const Platform = ({statistics}) => {
  const [selectedCurrency, setSelectedCurrency] = useState('BTC/USDT');
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2157 },
      items: 6.5
    },
    LargeDesktop: {
      breakpoint: { max: 2157, min: 1520 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 1520, min: 1230 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1230, min: 860 },
      items: 3
    },
    largeMobile: {
      breakpoint: { max: 860, min: 710 },
      items: 2
    },
    middleMobile: {
      breakpoint: { max: 710, min: 453 },
      items: 1.5
    },
    mobile: {
      breakpoint: { max: 453, min: 0 },
      items: 1.05
    }
  };
  const detailProps = useDetailProps();

  const filteredEntry = statistics.find((data) => data.attributes.currencies === selectedCurrency);
  const statistic = filteredEntry ? filteredEntry.attributes : [];
  const entries = statistic.entries;

  const currencyIcons = {
    'BTC': btc,
    'ETH': eth,
    'USDT': usdt
  };

  return (
    <div id='platform'>
      <div className="px-4 xl:px-v1.5 xxl:px-v1.5 bg-primary pb-28">
        <div className="leading-120 uppercase text-60 font-semibold sm:text-xl">
          Investment Platform - Alana
        </div>
        <div className="leading-120 uppercase text-60 font-semibold sm:text-xl">
          Where Algorithms Shape Success
        </div>
        <div className="sm:overflow-x-hidden relative image-container bg-no-repeat w-full mt-20 bg-black bg-no-repeat pt-v51 lg:pt-v280"
          style={{ borderTopRightRadius: '4rem', borderBottomLeftRadius: '4rem', borderBottomRightRadius: '4rem' }}>
          <div className="absolute top-0 h-full flex flex-col justify-between w-full">
            <img src={mask} className='absolute object-fill w-3/5 h-32 md:hidden sm:hidden' style={{position:0}}/>
            <img src={maskSmall} className='absolute object-fill w-3/4 hidden md:block sm:block' style={{position:0}}/>
            <div className='flex w-full items-end absolute' style={{bottom:0}}>
              <div className='md:hidden sm:hidden lg:hidden w-2/3-1 mr-v20'>
                <img className="object-cover w-full" src={computer} style={{height:'auto', objectFit:'contain'}}/>
              </div>
              <div className="text-yellow-1 leading-160 flex flex-col w-1/3 md:w-full sm:w-full lg:w-full justify-between">
                <div className="lg:px-v9">
                  <div className="mt-v16 lg:mt-v37">
                    <div className="text-bold text-20 font-bold lg:text-m45">
                    { getValue(detailProps, 'investmentTitle1') }
                    </div>
                    <div className="mr-v16 mt-v10 lg:mt-v30 leading-160 text-12 lg:text-m22">
                    { getValue(detailProps, 'investmentDescription1') }
                    </div>
                  </div>
                  <div className="mt-v16 lg:mt-v37">
                    <div className="text-bold text-20 font-bold lg:text-m45">
                    { getValue(detailProps, 'investmentTitle2') }
                    </div>
                    <div className="mr-v16 mt-v10 lg:mt-v30 leading-160 text-12 lg:text-m22">
                    { getValue(detailProps, 'investmentDescription2') }
                    </div>
                  </div>
                  <div className="mt-v16 lg:mt-v37">
                    <div className="text-bold text-20 font-bold lg:text-m45">
                    { getValue(detailProps, 'investmentTitle3') }
                    </div>
                    <div className="mr-v16 mt-v10 lg:mt-v30 leading-160 text-12 lg:text-m22">
                    { getValue(detailProps, 'investmentDescription3') }
                    </div>
                  </div>
                </div>
                <div className="flex mt-v37 lg:px-v9 items-center mb-v20 lg:mb-v37">
                  <img className="p-4 bg-gray-900 hidden" style={{borderRadius: '1rem'}}src={svg}>
                  </img>
                  <div className="text-center text-lg font-bold leading-160 lg:text-m37">
                    Powered by Alana
                  </div>
                </div>
                <div className="hidden sm:block md:block lg:block w-full">
                  <img src={computer}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='text-x22 xl:text-22 xxl:text-22 uppercase mt-10 mb-12 font-bold leading-160 text-center'>
          Algorithm Performance
        </div>
        <div className='flex md:flex-col sm:flex-col lg:flex-col'>
          <div className='flex flex-col justify-between bg-gray-280 border-30 w-1/3 md:w-full sm:w-full lg:w-full '>
            <div className='flex py-3 px-8 justify-between items-center'>
              <div className='text-base text-black font-bold'>2022</div>
              <div className='text-base text-black leading-160 uppercase'>12 July – 21 December</div>
            </div>
            <div className='flex bg-black leading-160 border-30 items-center'>
              <div className='flex text-center flex-col text-white mt-6 mb-5 w-1/2'>
                <div className='text-xs font-semibold uppercase leading-160'>Equity</div>
                <div className='flex text-x40 justify-center leading-120'>
                  <div className='font-normal'>$</div>
                  <div className='font-semibold'>10m</div>
                </div>
              </div>
              <div className='border-l-1 border-white opacity-30 h-16'></div>
              <div className='flex text-center flex-col text-white mt-6 mb-5 w-1/2'>
                <div className='text-xs font-semibold uppercase leading-160'>Profit</div>
                <div className='flex text-x40 justify-center leading-120'>
                  <div className='font-normal'>$</div>
                  <div className='font-semibold'>48m</div>
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col bg-gray-280 justify-around border-30 w-1/3 md:w-full sm:w-full lg:w-full mx-10 lg:mx-0 md:mx-0 sm:mx-0 md:my-10 lg:my-10'>
            <div className='flex py-3 px-8 self-center'>
              <div className='text-base text-black font-bold'>2022-2023</div>
            </div>
            <div className='flex bg-black leading-160 border-30 items-center'>
              <div className='flex text-center flex-col text-white mt-6 mb-5 w-1/2'>
                <div className='text-xs font-semibold uppercase leading-160'>Equity</div>
                <div className='flex text-x40 justify-center leading-120'>
                  <div className='font-normal'>$</div>
                  <div className='font-semibold'>25m</div>
                </div>
              </div>
              <div className='border-l-1 border-white opacity-30 h-16'></div>
              <div className='flex text-center flex-col text-white mt-6 mb-5 w-1/2'>
                <div className='text-xs font-semibold uppercase leading-160'>Profit</div>
                <div className='flex text-x40 justify-center leading-120'>
                  <div className='font-normal'>$</div>
                  <div className='font-semibold'>15m</div>
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col bg-gray-280 justify-around border-30 w-1/3 md:w-full sm:w-full lg:w-full '>
            <div className='flex py-3 px-8 self-center'>
              <div className='text-base text-black font-bold'>Current</div>
            </div>
            <div className='flex bg-black leading-160 border-30 items-center'>
              <div className='flex text-center flex-col text-white mt-6 mb-5 w-1/2'>
                <div className='text-xs font-semibold uppercase leading-160'>Equity</div>
                <div className='flex text-x40 justify-center leading-120'>
                  <div className='font-normal'>$</div>
                  <div className='font-semibold'>25m</div>
                </div>
              </div>
              <div className='border-l-1 border-white opacity-30 h-16'></div>
              <div className='flex text-center flex-col text-white mt-6 mb-5 w-1/2'>
                <div className='text-xs font-semibold uppercase leading-160'>Profit</div>
                <div className='flex text-x40 justify-center leading-120'>
                  <div className='font-normal'>$</div>
                  <div className='font-semibold'>6m</div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
{/* 
        <div className='text-x22 xl:text-22 xxl:text-22 uppercase mt-10 mb-12 font-bold leading-160 text-center'>
          Numbers from backtesting
        </div>

        <div className='relative h-96'>
          <div className='relative'>
              {statistics.map((data) => (
               <button
               key={data.id}
               className={`border-2 cursor-pointer px-4xl-1 text-15 font-bold py-2xl-1 uppercase border-gray-250 active:border-black active:bg-black hover:border-black hover:bg-black crypto-buttons text-black ml-1 rounded-xxl ${
                 selectedCurrency === data.attributes.currencies ? 'active' : ''
               }`}
               onClick={() => setSelectedCurrency(data.attributes.currencies)}
             >
               {data.attributes.currencies}
             </button>
              ))}
            <div className='flex flex-col border-2 bg-black average-return pt-3 pb-3 pr-4 pl-4 absolute top-0 right-0' style={{borderRadius: '30px'}}>
              <div className='text-yellow-1 text-10 uppercase'>Average return</div>
              <div className='text-yellow-1 text-16 mt-2 font-bold'>
                {statistic.averageReturn}%
              </div>
            </div>
          </div>
            <Carousel 
              responsive={responsive}
              swipeable={true}
              draggable={true}
              ssr={false}
              arrows={false}
              showDots={false}
              autoPlay={false}
              infinite={false}
              keyBoardControl={true}
              itemClass="card"
              className='mt-20 folder'
            >
            {(statistics.length > 0) && (
              entries.data.map((stat) => (
                  <div key={stat.id} className="card" style={{ width: '280px', minHeight:'210px' }}>
                  <div className="text-bold text-lg folder-year pr-8 pt-3 font-bold text-right">{stat.attributes.year}</div>
                  <div className='text-bold font-bold folder-head pl-7'>{stat.attributes.return}%</div>
                  <div className='flex gap-5 justify-center folder-foot'>
                    <div className='flex gap-1 text-bold font-bold'>
                      <img src={currencyIcons[statistic.currencyType1]} alt="" />
                      {stat.attributes.volume1}
                    </div>
                    <div className='flex gap-1 text-bold font-bold'>
                      <img src={currencyIcons[statistic.currencyType2]} alt="" />
                      {stat.attributes.volume2}
                    </div>
                  </div>
                  <img src={folder} style={{ position: 'absolute', zIndex: 0, top: 0}} draggable="false" alt="" />
                </div>
              )))}
          </Carousel>
        </div> */}
        
        </div>
    </div>
  )
}

export default Platform