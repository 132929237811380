const getValue = (config, key) => {
  if (!config) {
    return; // Return undefined if config is falsy
  }
  
  for (const element of config) {
    if (element.attributes.key === key) {
      return element.attributes.value; // Return the value if the key is found
    }
  }

  // Return a default value (e.g., null) if the key is not found
  return null;
}

export {
  getValue,
}