import React from 'react'
import { Link } from "react-router-dom";
import Logo from '../../images/Logo.svg';

const FooterBottom = () => {
    const pdf = require(`../../pdf/nanos_whitepaper.pdf`).default
    return (
        <div className="border-t-2 mt-36 border-gray-500 border-dashed pt-4 flex justify-between items-center sm:items-start sm:flex-col md:items-start md:flex-col border-opacity-15">
            <div className="flex items-start sm:mb-5 md:mb-5">
                <img className="pt-2 pr-2" src={Logo}>
                </img>
            </div>
            <div className="flex text-x17 sm:text-xs ">
                <div className="mr-5 sm:mr-2">
                    © Nanos Payments {new Date().getFullYear()}. All rights reserved
                </div>

                <div className="underline mr-4 cursor-pointer">
                    <a onClick={() => window.open(pdf)} target="_blank" rel="noopener noreferrer">
                        WHITEPAPER
                    </a>
                </div>

                <div className="underline">
                    <Link to="/legal">
                        LEGAL PAGES
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default FooterBottom