import React from 'react'
import vector from '../../images/Vector-1.svg'

import { useDetailProps } from '../../detailProvider';

import { getValue } from '../../utils';

const Service = () => {

  const detailProps = useDetailProps();
  console.log(getValue(detailProps, 'workDesciption'))
  return (
    <div id='work'>
    <div className="bg-primary px-4 xl:px-v1.5 xxl:px-v1.5 pt-10 pb-20">
      <div className="flex justify-between pb-20 text-100 sm:text-3xl">
        <div className="uppercase font-bold">How</div>
        <div className="uppercase font-bold">We</div>
        <div className="uppercase font-bold">Work</div>
      </div>
      <div className="flex sm:flex-col">
        <div className="flex flex-col justify-between w-2/5 sm:w-full sm:mb-10">
          <div className="uppercase leading-120 text-41 sm:text-xl font-semibold mr-14 lg:mr-12 md:mr-0 sm:mr-0">
            { getValue(detailProps, 'workTitle') }
          </div>
          <div>
            <img className="pl-11 mb-4 sm:pl-0 sm:hidden" src={vector}></img>
          </div>
        </div>
        <div className="w-3/5 tracking-184 mr-20 leading-107 text-41 sm:text-xl border-l-1 border-gray-270 pl-10 sm:w-full sm:border-t-2 sm:border-l-0 sm:pl-0 sm:pt-10 sm:mb-10 tracking-2 md:tracking-1 sm:tracking-1">
          { getValue(detailProps, 'workDesciption') }
        </div>
        <div>
          <img className="pl-11 mb-4 sm:pl-0 hidden sm:block" src={vector}></img>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Service