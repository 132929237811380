import React from "react";
import { Link as LinkRouter } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";

export const Nav = ({ children, scrollNav }) => {
  const background = "bg-primary";
  return (
    <nav
      className={`${background} h-24 -mt-24 font-bold font-pathway flex justify-center items-center text-base sticky top-0 z-10 lg:transition-all lg:duration-500 lg:ease-linear`}
    >
      {children}
    </nav>
  );
};

export const NavbarContainer = ({ children }) => (
  <div className="flex justify-between h-20 z-10 w-full py-0 px-4 xl:px-v1.5 xxl:px-v1.5 max-w-screen-lg font-bold">
    {children}
  </div>
);

export const NavLogo = ({ children, ...rest }) => (
  <LinkRouter
    {...rest}
    className="text-white justify-self-start cursor-pointer text-2xl flex items-center ml-0.5 font-bold no-underline xl:w-1/40-0 xxl:w-1/40-0"
  >
    {children}
  </LinkRouter>
);

export const MobileIcon = ({ children }) => (
  <div
    className="hidden lg:flex lg:absolute lg:top-5 lg:right-5 lg:text-3xl lg:cursor-pointer lg:text-white"
  >
    {children}
  </div>
);

export const NavMenu = ({ children }) => (
  <ul className="flex items-center list-none text-center -ml-5 lg:hidden">
    {children}
  </ul>
);

export const NavItem = ({ children }) => <li className="h-20 text-sm xl:text-13 xxl:text-13 font-bold px-4 xl:px-v2.25 xxl:px-v2.25">{children}</li>;

export const NavLinks = ({ children, ...rest }) => (
  <LinkScroll
    {...rest}
    className="text-black flex items-center leading-70 py-0 px-4 xl:px-v1.5 xxl:px-v1.5 h-full cursor-pointer"
  >
    {children}
  </LinkScroll>
);

export const NavBtn = ({ children }) => (
  <nav className="flex items-center">{children}</nav>
);

export const NavBtnLink = ({ children, ...rest }) => (
  <div {...rest} className="border-1 text-black font-bold px-4 xl:px-v1.5 xxl:px-v1.5 sm:px-4 text-sm py-2 uppercase border-black cursor-pointer xl:text-13 xxl:text-13" style={{borderRadius:'50px'}}>
    {children}
  </div>
);
