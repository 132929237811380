import React from 'react'
import vector from '../../images/Vector(white).svg';

import { useDetailProps } from '../../detailProvider';

import { getValue } from '../../utils';

const About = () => {

  const detailProps = useDetailProps();

  return (
    <div id='token'> 
    <div className="bg-primary pb-36 px-4 xl:px-v1.5 xxl:px-v1.5 text-white">
        <div 
          className="text-yellow-1 image-container bg-cover bg-center relative bg-no-repeat w-full bg-Token sm:bg-TokenSmall" 
          style={{paddingTop:'56%', borderRadius: '4rem', minHeight:'783px'}}>
            <div className='absolute top-0 w-full h-full flex md:flex-col sm:flex-col justify-between md:justify-start 
            sm:justify-start md:h-full sm:h-full px-10 pt-8 pb-10 xl:px-v3 xxl:px-v3 xl:pt-v2.5 xxl:pt-v2.5 xl:pb-v3 xxl:pb-v3'>
              <div className="flex flex-col md:flex-row sm:flex-row justify-between">
              <div className="text-100 sm:text-3xl font-semibold">
                NANOS 
              </div>
              <div className='mb-2'>
                <img src={vector}>
                </img>
              </div>
            </div>
            <div className="w-1/2-0 lg:w-full md:w-full sm:w-full md:mr-0 mb-4 sm:mr-0">
              <div className="text-x22 leading-160 mb-3.5 font-bold sm:text-lg xl:text-22 xxl:text-22">
                { getValue(detailProps, 'tokenTitle') }
              </div>
              <div className='text-xs xl:text-12 xxl:text-12 leading-160 mr-4 xl:mr-v7 xxl:mr-v7'>
                { getValue(detailProps, 'tokenDescription') }
            <div className="leading-160 flex flex-col justify-between items-end md:items-start md:h-full sm:items-start sm:h-full">
              <div className="text-100 sm:text-3xl font-semibold">
                &gt;TOKEN
              </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default About