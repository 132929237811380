import React from "react";
import { Link as LinkScroll } from "react-scroll";
import { Link as LinkRouter } from "react-router-dom";

import { FaTimes } from "react-icons/fa";

export const ContactContainer = ({ children, isOpen }) => {
  const opacity = isOpen ? "opacity-100" : "opacity-0 hidden";
  const top = isOpen ? "top-0" : "-top-50";
  const zIndex = isOpen ? "z-50" : "z-0";

  return (
    <div
      className={`fixed ${zIndex} w-full bg-primary px-5 top-0 ${opacity} ${top}`}
    >
      {children}
    </div>
  );
};

export const CloseIcon = ({ ...rest }) => (
  <div {...rest} className="flex text-black text-base items-center">
    <div className="mr-1.5">Close</div>
    <FaTimes color="black" />
  </div>
);

export const SidebarActionLink = () => (
  <div className="flex text-black text-base items-center">
    <div className="mr-16">LinkedIn</div>
  </div>
);

export const NavLogo = ({ children, ...rest }) => (
  <LinkRouter
    {...rest}
    className="text-white justify-self-start cursor-pointer text-2xl flex items-center ml-0.5 font-bold no-underline"
  >
    {children}
  </LinkRouter>
);

export const SidebarLinkWrap = ({ children, ...rest }) => (
  <div
    {...rest}
    className="flex justify-between h-20 flex bg-transparent text-3xl cursor-pointer outline-none"
  >
    {children}
  </div>
);

export const SidebarWrapper = ({ children }) => (
  <div className="flex text-black flex-col md:mb-28 sm:mb-28">{children}</div>
);

export const FormContainer = () => (
  <div className="mt-20 pb-24 pl-20 md:pl-12 sm:pl-8 md:pr-12 sm:pr-8 sm:pb-10 w-1/2 md:w-full sm:w-full">
    <div className="text-5xl mb-16 sm:mb-12 text-black">
      Contact us
    </div>
    <div className="mb-10 sm:mb-8">
      <input className="appearance-none border-b-1 rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Username" />
    </div>
    <div className="mb-10 sm:mb-8">
      <input className="appearance-none border-b-1 rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline" id="email" type="text" placeholder="Email" />
    </div>
    <div className="mb-10 sm:mb-8">
      <input className="appearance-none border-b-1 rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline" id="phone" type="text" placeholder="Phone" />
    </div>
    <div className="mb-6 sm:mb-4">
      <textarea rows="4"  max-rows="4" className="appearance-none border-b-1 rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline" id="phone" type="text" placeholder="Your messagePhone" />
    </div>
    <div className="inline-flex justify-center items-center py-4 px-8 bg-black rounded-4xl text-white">
      Send message
    </div>
  </div>
);

export const SidebarLink = ({ children, ...rest }) => (
  <LinkScroll
    {...rest}
    className="justify-center text-2xl text-black opacity-50 hover:ml-2.5 hover:opacity-100 cursor-pointer no-underline list-none transition ease-in-out duration-200"
  >
    {children}
  </LinkScroll>
);

export const SideBtnWrap = ({ children }) => (
  <div className="flex flex-col text-center w-1/2 md:w-full sm:w-full justify-center">{children}</div>
);

export const SidebarRoute = ({ children, ...rest }) => (
  <LinkRouter {...rest} className="text-2xl">
    {children}
  </LinkRouter>
);

export const SidebarDescription = ({ children }) => (
  <div className="text-lg mx-36 lg:mx-20 md:mx-0 sm:mx-0 mt-8">
    {children}
  </div>
);

export const NavBtn = ({ children }) => (
  <nav className="flex items-center">{children}</nav>
);

export const NavBtnLink = ({ children, ...rest }) => (
  <div {...rest} className="border-2 px-4 py-2 text-sm uppercase border-black font-bold" style={{borderRadius:'50px'}}>
    {children}
  </div>
);
